import React from "react";

export default function HeaderModal({ logOut, closeLogOutModal }) {
  const handleLogOut = () => {
    logOut();
    closeLogOutModal();
  };
  return (
    <div className="fixed mx-auto  lg:w-1/3 inset-0 flex items-center justify-center z-20  bg-transparent opacity-90">
      <div role="alert" className="alert mx-2 py-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="stroke-info h-6 w-6 shrink-0"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
        <span>আপনি কি লগ আউট করতে চান?</span>
        <div>
          <button
            onClick={closeLogOutModal}
            className="btn btn-sm mx-2 btn-primary"
          >
            না
          </button>
          <button onClick={handleLogOut} className="btn btn-sm hover:btn-error">
            হ্যা
          </button>
        </div>
      </div>
    </div>
  );
}
