import { faMoneyBill1 } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { PiCircleBold } from "react-icons/pi";

export default function FiqhCourseFee() {
  return (
    <section className="my-20">
      <p className="text-xl my-4">কোর্স ফীঃ</p>
      <div className="grid grid-cols-1 md:grid-cols-4">
        <div className="relative overflow-hidden md:order-2 w-56 h-28 rounded py-2 px-5 mx-auto my-3 md:my-0 bg-gradient-to-tl from-cyan-200 to-cyan-100 payment-card">
          <div className="flex justify-between">
            <div className="mr-10">
              <p className="text-nowrap">কোর্স ফী : লেভেল ১</p>
            </div>
            <FontAwesomeIcon
              className={`text-2xl text-cyan-600 `}
              icon={faMoneyBill1}
            />
          </div>
          <PiCircleBold className="text-8xl absolute -ml-14 mt-2  opacity-10 text-inherit" />
          <p className={`text-center font-bold text-xl my-2 text-cyan-600 `}>
            <span className="uppercase font-bold text-2xl">৳১৫০০</span>
          </p>
        </div>
        <div className="relative overflow-hidden md:order-2 w-56 h-28 rounded py-2 px-5 mx-auto my-3 md:my-0 bg-gradient-to-tl from-green-200 to-green-100 payment-card">
          <div className="flex justify-between">
            <div className="mr-10">
              <p className="text-nowrap">কোর্স ফী : লেভেল ২</p>
            </div>
            <FontAwesomeIcon
              className={`text-2xl text-green-600 `}
              icon={faMoneyBill1}
            />
          </div>
          <PiCircleBold className="text-8xl absolute -ml-14 mt-2  opacity-10 text-inherit" />
          <p className={`text-center font-bold text-xl my-2 text-green-600 `}>
            <span className="uppercase font-bold text-2xl">৳১৫০০</span>
          </p>
        </div>

        <div className="md:col-span-2">
          <p>কোর্স ফী পাঠাতে হবে। তারপর টেলিগ্রাম গ্রুপে জয়েন করতে হবে।</p>
          <p>কোর্স ফী ১৫০০ টাকা। ভর্তির পর আর কোন খরচ নেই।</p>
        </div>
      </div>
    </section>
  );
}
