import React, { useState } from "react";

export default function SendMessage({ receiver }) {
  const [reply, setReply] = useState("");

  const chatId = receiver?.message?.from?.id || 1704862497; //admin group: -1001508442718, sadia: 5557176044
  const url = `https://api.telegram.org/${
    process.env.REACT_APP_TELEGRAM_API
  }/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(reply)}`;
  const handleChange = (e) => {
    setReply(e.target.value);
  };
  const handleSendMessage = () => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          console.log(`Message sent !`);
          setReply("");
        } else {
          console.error("Failed to send message:", data.description);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className="w-full h-10 rounded ">
      <div className="flex gap-2">
        <input
          type="text"
          placeholder="Write a reply"
          onChange={handleChange}
          value={reply}
          className="border border-gray-300 rounded-lg px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <button
          onClick={handleSendMessage}
          className="ml-auto bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg"
        >
          Send
        </button>
      </div>
    </div>
  );
}
