import { useEffect, useState } from "react";
import { getAdmissionDate } from "../Components/CourseRegistration/admissionDates";
import CourseCard from "./CourseCard";
import Loading from "./Loading/Loading";

const Courses = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(getAdmissionDate());

  useEffect(() => {
    setData(getAdmissionDate());

    if (data && data.length > 0) {
      setLoading(false);
    }
  }, []);

  if (loading) return <Loading />;
  return (
    <div className="container mx-auto my-10 mb-40">
      <p className="text-center text-2xl">আমাদের কোর্স সমূহ</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-10  justify-items-center my-10 mx-4 ">
        {data.map((course) => (
          <CourseCard key={course.name} course={course} />
        ))}
      </div>
    </div>
  );
};

export default Courses;
