import React from "react";

const StudentImport = () => {
  const handleChange = (e) => {
    console.log(e.target.value);
  };

  return (
    <>
      {/* <section className="min-h-screen container mx-auto my-10 px-5">
       <p className="text-2xl text-center mb-5">
         Student Import{" "}
         <FontAwesomeIcon
           className=""
           icon={faArrowAltCircleDown}
         ></FontAwesomeIcon>
       </p>

       <div>
         <section className="bg-green-200 p-5 rounded">
           <p className="text-xl mb-5">Get Student</p>

           <form onSubmit={handleSubmit}>
             <div className="flex items-end gap-10 mb-5">
               <div className="form-control">
                 <label className="label">
                   <span className="label-text">Course Name</span>
                 </label>
                 <input
                   type="text"
                   placeholder="aqeedah"
                   className="input input-bordered w-full max-w-xs"
                   {...register("prev_course", { required: true })}
                 />
                 {errors.prev_course?.type === "required" &&
                   "Course Name is required"}
               </div>

               <div className="form-control">
                 <label className="label">
                   <span className="label-text">Batch No</span>
                 </label>
                 <input
                   type="text"
                   placeholder="19"
                   className="input input-bordered w-full max-w-xs"
                   {...register("prev_batch", { required: true })}
                 />
                 {errors.prev_batch?.type === "required" &&
                   "Batch Number is required"}
               </div>

               <div className="form-control">
                 <label className="label">
                   <span className="label-text">Serial No</span>
                 </label>
                 <input
                   type="text"
                   placeholder="102"
                   className="input input-bordered w-full max-w-xs"
                   {...register("prev_serial", { required: true })}
                 />
                 {errors.prev_batch?.type === "required" &&
                   "Serial Number is required"}
               </div>

               <button type="submit" className="btn btn-primary">
                 Get
               </button>
               <input
                 onClick={() => clearErrors()}
                 type="reset"
                 className="btn btn-error"
               />
             </div>
           </form>
         </section>
       </div>
       <br />
       <div>
         <section className="bg-orange-200 p-5 rounded">
           <p className="text-xl mb-5">Import Student</p>

           <form onSubmit={handleSubmit(setStudent)}>
             <div className="flex items-end gap-10 mb-5">
               <div className="form-control">
                 <label className="label">
                   <span className="label-text">Course Name</span>
                 </label>
                 <input
                   type="text"
                   placeholder="aqeedah"
                   className="input input-bordered w-full max-w-xs"
                   {...register("new_course", { required: true })}
                 />
                 {errors.new_course?.type === "required" &&
                   "Course Name is required"}
               </div>

               <div className="form-control">
                 <label className="label">
                   <span className="label-text">Batch No</span>
                 </label>
                 <input
                   type="text"
                   placeholder="19"
                   className="input input-bordered w-full max-w-xs"
                   {...register("new_batch", { required: true })}
                 />
                 {errors.new_batch?.type === "required" &&
                   "Batch Number is required"}
               </div>

               <div className="form-control">
                 <label className="label">
                   <span className="label-text">Serial No</span>
                 </label>
                 <input
                   type="text"
                   placeholder="102"
                   className="input input-bordered w-full max-w-xs"
                   {...register("new_serial", { required: true })}
                 />
                 {errors.new_batch?.type === "required" &&
                   "Serial Number is required"}
               </div>

               <button type="" className="btn btn-secondary">
                 Import
               </button>
             </div>
           </form>
         </section>
       </div>
    </section> */}
    </>
  );
};

export default StudentImport;
