import { getCourseData } from "./courseData";
const data = getCourseData();

function getAdmissionDate(courseName) {
  if (courseName) {
    const course = data.find((item) => item.course === courseName);
    return course
      ? course.startDate || "Start date not available"
      : "Course not found";
  } else {
    return data;
  }
}

function upcommingCourses() {
  // Sort based on closest upcoming date
  return data
    .filter((course) => {
      const date = new Date(course.startDate);
      return !isNaN(date); // Keep only entries with valid dates
    })
    .sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateA - dateB; // Sort in ascending order (closest date first)
    });
}

export { getAdmissionDate, upcommingCourses };
