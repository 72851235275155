import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTelegram } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import waving_hand from "../assets/icons/waving_hand.gif";

import React, { useEffect, useRef, useState } from "react";

const MessengerPlugin = () => {
  const [isOpen, setIsOpen] = useState(false);
  const pluginRef = useRef(null);

  // Toggle the chat button state
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  // Close the plugin when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (pluginRef.current && !pluginRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div
      ref={pluginRef}
      className="fixed bottom-24 lg:bottom-16 right-6 flex flex-col items-center z-50"
    >
      {/* Text that appears when the button is clicked */}
      {isOpen && (
        <div>
          <div className="mb-2 p-2 bg-slate-200 text-black rounded shadow-md opacity-90 ">
            কোন হেল্প লাগবে ?
          </div>
          <div
            className="mb-2 p-2 bg-indigo-500 text-white rounded shadow-md cursor-pointer opacity-90 hover:bg-indigo-600 "
            onClick={() => window.open("https://m.me/alharamain.ac", "_blank")}
          >
            <FontAwesomeIcon icon={faFacebookMessenger} /> Messenger
          </div>
          <div
            className="mb-2 p-2 bg-green-500 text-white rounded shadow-md cursor-pointer opacity-90 hover:bg-green-600 "
            onClick={() => window.open("https://wa.me/966578621278", "_blank")}
          >
            <div className="flex items-center gap-1">
              <IoLogoWhatsapp />
              <p>WhatsApp</p>
            </div>
          </div>
          <div
            className="mb-2 p-2 bg-sky-600 text-white rounded shadow-md cursor-pointer opacity-90 hover:bg-sky-700 "
            onClick={() => window.open("https://t.me/+966578621278", "_blank")}
          >
            <div className="flex items-center gap-1">
              <FaTelegram />
              <p>Telegram</p>
            </div>
          </div>
        </div>
      )}

      {isOpen ? (
        <button
          className="btn btn-circle btn-ghost bg-slate-400 opacity-60"
          onClick={handleClick}
        >
          <FontAwesomeIcon className="text-black" icon={faX} />
        </button>
      ) : (
        <button
          className="bg-gradient-to-r from-indigo-500 to-cyan-400 text-white rounded-full pl-3 pr-4 py-2 shadow-lg hover:from-green-700 hover:to-lime-400 transition duration-700 opacity-90"
          onClick={handleClick}
        >
          <div className="flex gap-1">
            <img src={waving_hand} alt="" />
            <p>সালাম !</p>
          </div>
        </button>
      )}
    </div>
  );
};

export default MessengerPlugin;
