const digitConverter = (en_digit) => {
  const banglaDigits = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];
  return en_digit
    .toString()
    .split("")
    .map((digit) => banglaDigits[digit])
    .join("");
};

export default digitConverter;
