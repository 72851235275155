import React from "react";

export default function ResultPageSkeleton() {
  return (
    <div className="mx-auto my-20 mb-52 flex w-10/12 flex-col gap-4">
      <div className="flex items-center gap-4 mb-10">
        <div className="skeleton h-16 w-full"></div>
        <div className="skeleton h-16 w-16 shrink-0 rounded-full"></div>
      </div>

      {/* <div className="skeleton h-4 w-28"></div> */}
      <div className="skeleton h-8 w-full"></div>
      <div className="skeleton h-8 w-full"></div>
      <div className="skeleton h-8 w-full"></div>
    </div>
  );
}
