import React from "react";
import bd_flag from "../../../assets/icons/bd_flag.ico";
const Aqeedah1Schedule = () => {
  return (
    <div className="my-10 py-10 bg-blue-200 rounded schedule_card">
      <div className="text-xl text-center my-10">
        <p className="">সহীহ আক্বীদাহ কোর্স - লেভেল ১</p>
        <p className=""> সপ্তাহে ৩ দিন</p>
      </div>
      <div className="overflow-x-auto ">
        <table className="table w-3/4 mx-auto text-xl ">
          <thead>
            <tr>
              <th></th>
              <th className="text-xl">বার</th>
              <th className="text-xl">
                <span className="flex items-center">
                  <span>সময়</span>
                  <div className="ml-3">
                    <img className="" src={bd_flag} alt="" />
                  </div>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>১</th>
              <td>রবিবার</td>
              <td>সন্ধ্যা ৭:৩০</td>
            </tr>

            <tr>
              <th>২</th>
              <td>মঙ্গলবার</td>
              <td>সন্ধ্যা ৭:৩০</td>
            </tr>

            <tr>
              <th>৩</th>
              <td>বৃহস্পতিবার</td>
              <td>সন্ধ্যা ৭:৩০</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Aqeedah1Schedule;
