import React from 'react';
import JsonUpdater from './JsonUpdater';

const ScholarshipUpdater = () => {
    return (
        <div className='container mx-auto mt-16 mb-20 px-5 min-h-screen'>           

        <JsonUpdater/>         
            
        </div>
    );
};

export default ScholarshipUpdater;