export const aqeedahExamDates = (batch, level, exmNo) => {
  const dates = {
    23: {
      level1: ["", "", ""],
      level2: ["", "", "", ""],
    },
  };

  const levelKey = `level${level}`;
  const dateText = dates[batch]?.[levelKey]?.[exmNo] || "";

  return <p>{dateText}</p>;
};
