const calculateRemainingDays = (date) => {
  // date parameter format : "30 Nov 2024"

  const today = new Date();
  const admissionStartDate = new Date(date);

  if (admissionStartDate <= today) {
    return { months: 0, days: 0 };
  }

  const oneDay = 24 * 60 * 60 * 1000;
  const totalDays = Math.round(Math.abs((admissionStartDate - today) / oneDay));
  const months = Math.floor(totalDays / 30); //remaining months
  const days = (totalDays % 30) - 1; // remaining days, subtracted 1, because we will start on that day

  return { months, days };
};

export default calculateRemainingDays;
