import React from "react";
import CardSeeratNextBatch from "./CardSeeratNextBatch";
import SeeratRegistrationForm from "./SeeratRegistrationForm";

export default function SeeratRegistration() {
  return (
    <section>
      <SeeratRegistrationForm />
      <CardSeeratNextBatch />
    </section>
  );
}
