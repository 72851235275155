import React from "react";

import { faCircleCheck, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bd_flag from "../../../assets/icons/bd_flag.ico";
import prize from "../../../assets/icons/gift_box.png";
import partyFlag from "../../../assets/partyFlag.png";
import seerat_class_img from "../../../assets/seerat/madina.png";
import CardSeeratNextBatch from "./Registration/CardSeeratNextBatch";
import Seerat1Schedule from "./Seerat1Schedule";
import SeeratCourseFee from "./SeeratCourseFee";
const seerat_1_syllabus = [
  "সীরাতের সংজ্ঞা ও মর্যাদা",
  "আদর্শ জীবন গড়ার গাইড",
  "ইসলামপূর্ব আরবের অবস্থা",
  "রাসূল (সা) এর বংশ পরিচয়",
  "হস্তী বাহীনির ঘটনা",
  "বক্ষ বিদীর্নের ঘটনা।",
  "হিলফুল ফযুল (সন্মানিতদের চুক্তি)",
  "নবীজীর বিবাহ ও সন্তানাদি",
  "কা’বা ঘর সংস্করণ এবং হাজরে আসওয়াদের পুনঃস্থাপন",
  "নবুওতের সূচনা",
  "জিবরীল (আ) এর ওহী নিয়ে আগমন",
  "ওহীর প্রকারভেদ ও প্রাথমিক অবতারণা",
  "দা’ওয়াতের যুগ এবং তার স্তরসমূহ",
  "মক্কী যুগে দা’ওয়াতের প্রথম স্তর",
  "মেরাজের ঘটনা",
  "সালাত",
  "মক্কী পর্যায়ের মধ্যবর্তী স্তর",
  "আরকাম ইবন আবীল আরকামের গৃহ",
  "মক্কী যুগের তৃতীয় পর্যায়",
  "মুমিনদের কষ্ট ও অত্যাচারের দাওয়াত",
  "নাজরানিদের মহাজিরে গমন",
  "নবীজী (সা) এর মদীনায় আগমন",
  "মাদানী যুগের সূচনা",
  "মদীনার প্রধান শত্রু ইহুদী সম্প্রদায়",
  "মসজিদে নববীর নির্মাণ",
  "ইহুদীদের সাথে চুক্তি সম্পাদন (মদীনার সনদ)",
  "যুদ্ধ-মিশন",
  "সাইরিয়া ও গাযওয়াহ",
  "বদরের যুদ্ধ",
  "বদরের যুদ্ধের পরবর্তী সামরিক কার্যক্রম",
  "কা'ব ইবন আশরাফের পতন",
  "ওহুদের যুদ্ধ",
  "ওহুদের পরবর্তী সামরিক অভিযানের প্রকৃতি",
  "খন্দকের যুদ্ধ",
  "খন্দক ও কুরাইযার যুদ্ধের পরবর্তী সামরিক কার্যক্রম",
  "হুদায়বিয়ার সন্ধি",
  "বাদশাহ এবং আমীরদের কাছে দাওয়াত পত্র প্রেরণ",
  "খায়বার এবং ওয়াদিউল কুরার যুদ্ধ",
  "কাজা উমরাহ পালন",
  "মুতা যুদ্ধ",
  "মক্কা বিজয়",
  "হুনায়নের যুদ্ধ",
  "তায়েফের যুদ্ধ",
  "তাবুক যুদ্ধ",
  "প্রতিনিধিদলের আগমন",
  "বিদায় হজ্জ",
  "সর্বশেষ সামরিক অভিযান",
  "বিদায় ইয়া রসূলুল্লাহ্ (সা)",
];
const SeeratInfo = () => {
  return (
    <div className="container mx-auto px-5 my-10">
      <section>
        <div className="rounded bg-gradient-to-tl from-sky-200 flex justify-between header-card">
          <div className="p-5">
            <p className="text-3xl">সীরাতুন নবী (সা:) কোর্সে</p>
            <p className="text-4xl">স্বাগতম!</p>
          </div>
          <div className="p-5 hidden md:inline">
            <div className="flex justify-between">
              <div className="text-right font-serif align-middle mr-10">
                <p className="text-4xl ">دورة السيرة النبوية</p>
                <p className="text-2xl">صلى الله عليه و سلم</p>
              </div>
              <div>
                <img src={partyFlag} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Course Descripttion  */}

      <section className="my-10">
        <p className="text-xl">কোর্স পরিচিতিঃ</p>
        <p>
          এই কোর্সটি তাদেরই কাজে আসবে যারা আল্লাহ ও তাঁর রসুলের উপর বিশ্বাস
          স্থাপন করে এবং সে অনুযায়ী সৎকর্ম সম্পাদন করে।তাদের ঈমানের জোতির
          বদৌলতে আল্লাহ তাদেরকে সুপথে পরিচালিত করেন। তারা ইহকাল ও পরকালে সফলকাম
          হবে এবং তাদের জন্য রয়েছে জান্নাত।
        </p>
        <p>
          আমাদের সকালে ঘুম থেকে জেগে উঠা থেকে শুরু করে ঘুমাতে যাওয়া পর্যন্ত
          প্রতিটি সময় এবং প্রতিটি আমল নাবী সাল্লাল্লাহু আলাইহি ওয়া সাল্লামের
          অনুসরনে হতে হবে।
        </p>
      </section>

      {/* in short  */}
      <section className="mt-20 mb-10">
        <div className="flex flex-col md:flex-row justify-around items-center">
          <div className="mb-8 md:mb-0">
            <img className="rounded-lg" src={seerat_class_img} />
          </div>
          <div>
            <p className="text-xl my-4">এক নজরে</p>

            <p>
              <FontAwesomeIcon
                className="text-green-600 pr-2"
                icon={faCircleCheck}
              />
              ৩ মাসের কোর্স
            </p>
            <p>
              <FontAwesomeIcon
                className="text-green-600 pr-2"
                icon={faCircleCheck}
              />
              কোর্স শেষে সার্টিফিকেট
            </p>
            <p>
              <FontAwesomeIcon
                className="text-green-600 pr-2"
                icon={faUserGroup}
              />
              পুরুষ - মহিলা আলাদা গ্রুপ
            </p>
            {/*<p>
              <FontAwesomeIcon
                className="text-red-600 pr-2"
                icon={faRecordVinyl}
                fade
              />
              ওয়ান-টু-ওয়ান লাইভ ক্লাস
            </p>*/}
            <p>
              <FontAwesomeIcon
                className="text-green-600 pr-2"
                icon="fa-solid fa-clock"
              />
              সপ্তাহে ৩ দিন রেকর্ডেড ক্লাস ও ক্লাস টেস্ট
            </p>
          </div>
        </div>
      </section>

      {/* Gift */}
      <section className="my-20 bg-yellow-200 rounded p-5 gift-box">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center ">
          <div className="order-2 mx-auto">
            <p className="text-xl my-4">সেরা ৫ জনকে পুরষ্কৃত করা হবে</p>
            <p>এই কোর্সে ৩টি ১০০ মার্কের পরীক্ষা হবে।</p>
            <p>
              পরীক্ষায় সর্বোচ্চ রেজাল্টকারীদের মধ্যে সেরা ৫ জনকে পুরষ্কৃত করা
              হবে।
            </p>
          </div>
          <div className="order-1 md:order-3">
            <img className="rounded-lg block mx-auto my-10 " src={prize} />
          </div>
        </div>
      </section>

      {/* Syllabus  */}
      <section>
        <div className="mt-10">
          <p className="text-xl my-8">কোর্সের বিষয়বস্তুঃ</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            {seerat_1_syllabus.map((item, i) => (
              <p key={i}>
                <FontAwesomeIcon
                  className="text-green-700 pr-2"
                  icon={faCircleCheck}
                />
                {item}
              </p>
            ))}
          </div>
        </div>
      </section>

      {/* schedule  */}
      <section className="my-24">
        <div className="flex items-center">
          <span className="text-xl">ক্লাসের সময়ঃ </span>
          <div className="ml-3">
            <img className="" src={bd_flag} alt="" />
          </div>
          <span className="ml-3">বাংলাদেশের সময় অনুযায়ী</span>
        </div>
        <p>পরবর্তী ব্যাচে ক্লাস টাইম ভিন্ন হবে।</p>
        <p>
          💠 রেকর্ডেড ক্লাসগুলো শায়খ আব্দুল্লাহ আল কাফী (রহি.) এর টেক্সট, অডিও
          দিয়ে নেওয়া হবে।
        </p>
        <Seerat1Schedule />
      </section>

      {/* admission system  */}
      <SeeratCourseFee />

      <CardSeeratNextBatch />
      {/* </section> */}
    </div>
  );
};

export default SeeratInfo;
