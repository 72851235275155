import React from "react";

import { faMousePointer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { getAdmissionDate } from "../Components/CourseRegistration/admissionDates.js";

const Registration = () => {
  let courses = getAdmissionDate();

  // Sort based on closest upcoming date
  courses = courses
    .filter((course) => {
      const date = new Date(course.startDate);
      return !isNaN(date); // Keep only entries with valid dates
    })
    .sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);
      return dateA - dateB; // Sort in ascending order (closest date first)
    });

  return (
    <div className="container mx-auto px-5 my-10">
      <section className="my-10">
        <p className="text-center text-2xl">
          পরবর্তীতে যে কোর্সগুলোতে ভর্তি শুরু হবে!
        </p>
      </section>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 registration_info_card">
        {courses.map((course, i) => (
          <Link key={i} to={`/registration-${course.course}`}>
            <div className="p-3 rounded bg-orange-200">
              <span className="underline">{course.name}</span>
              <FontAwesomeIcon
                className="ml-3 text-orange-600"
                icon={faMousePointer}
                fade
              />

              <p>ভর্তি শুরু হবেঃ {course.startDate}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Registration;
