import { faMousePointer } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { getAdmissionDate } from "../../../CourseRegistration/admissionDates";
import calculateRemainingDays from "../../../utils/calculateRemainingDays";
import digitConverter from "./../../../utils/digitConverter";

const CardFiqhNextBatch = () => {
  const location = useLocation();
  const onRegistrationPage =
    location.pathname === "/registration-fiqh" ? true : false;

  const onCoursePage = location.pathname === "/courses/fiqh" ? true : false;

  const today = new Date();
  const admission_data = getAdmissionDate("fiqh");
  const addmissionStartDate = new Date(admission_data);

  const data = calculateRemainingDays(new Date(addmissionStartDate));

  const m = digitConverter(data.months);
  const d = digitConverter(data.days);

  return (
    <div className="bg-sky-100 p-5 md:p-16 rounded text-white my-10 next_batch_reg_card">
      <div className="mb-10 next_batch_reg_heading">
        {today < addmissionStartDate ? (
          <div>
            <p className="text-center text-sky-900  text-3xl mb-4">
              ইসলামী ফিকহ কোর্সের পরবর্তী ব্যাচে ভর্তি শুরু হবে
            </p>
            <p className="text-center text-black text-2xl">
              আর মাত্র{" "}
              <span className={` ${m === "০" ? "hidden" : ""}`}>{m} মাস </span>{" "}
              <span className={` ${d === "০" ? "hidden" : ""}`}>{d} দিন</span>{" "}
              পর!
            </p>
          </div>
        ) : (
          <p className="text-center text-sky-900  text-3xl mb-4">
            ইসলামী ফিকহ কোর্সের ৯ম ব্যাচে ভর্তি চলছে!
          </p>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-black text-xl next_batch_reg_text">
        {/* <p className="p-3 bg-green-300 rounded">ভর্তি শুরু হবে ১৫ নভেম্বর</p> */}
        <p className="p-3 bg-orange-300 rounded">ভর্তি শেষ হবে ২৫ নভেম্বর </p>
        <p className="p-3 bg-blue-300 rounded">অরিয়েন্টেশন ক্লাস ২৭ নভেম্বর</p>
        {/* <p className='p-3 bg-yellow-300 rounded'>ক্লাস শুরু হয়েছে</p> */}
        {!onCoursePage && (
          <Link to="/courses/fiqh">
            <p className="p-3 bg-pink-300 rounded shadow-md">
              কোর্স সম্পর্কে বিস্তারিত জানুন{" "}
              <FontAwesomeIcon
                className="text-pink-600"
                icon={faMousePointer}
                fade
              />
            </p>
          </Link>
        )}
        {!onRegistrationPage && (
          <Link to="/registration-fiqh">
            <p className="p-3 bg-green-300 rounded shadow-md">
              রেজিস্ট্রেশন করুন{" "}
              <FontAwesomeIcon
                className="text-green-600"
                icon={faMousePointer}
                fade
              />
            </p>
          </Link>
        )}
      </div>
    </div>
  );
};

export default CardFiqhNextBatch;
