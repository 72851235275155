// utils.js (or tools_submissionDateTime.js based on your naming)
export function formatDateTime() {
  const now = new Date();

  const day = now.getDate();
  const month = now.toLocaleString("default", { month: "short" });
  const year = now.getFullYear();

  const hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  const formattedHours = hours % 12 || 12;
  const formattedMinutes = String(minutes).padStart(2, "0");

  return `${day} - ${month} - ${year}, ${formattedHours}:${formattedMinutes} ${ampm}`;
}
