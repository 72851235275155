import React from "react";
import { LuBookMarked } from "react-icons/lu";
import { Link } from "react-router-dom";

const CourseCard = ({ course }) => {
  return (
    <div
      className={`p-5 rounded w-full bg-gradient-to-tr ${
        course.theme[0] || "from-blue-500"
      } ${
        course.theme[1] || "to-sky-400"
      } shadow-xl relative overflow-hidden course_card`}
    >
      <Link className="block" to={course.course}>
        <div>
          <div className="p-2 w-10 my-3 rounded bg-black opacity-20">
            <span className="text-2xl text-white">
              <LuBookMarked />
            </span>
          </div>
          <div className="flex w-full">
            <p className="font-bold mr-2">{course.name}</p>
            {course?.badge && (
              <p className={`badge ${course?.badge_cls}`}>{course?.badge}</p>
            )}
          </div>

          <p>কোর্সের মেয়াদ: {course.duration}</p>
          <p>কোর্স টিচার: {course.teacher}</p>
        </div>
        <div>
          <span className="text-9xl text-white opacity-10 absolute -bottom-5 -right-7 pointer-events-none">
            <LuBookMarked />
          </span>
        </div>
      </Link>
    </div>
  );
};

export default CourseCard;
