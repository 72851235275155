import { faRecordVinyl } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import bd_flag from "../../../assets/icons/bd_flag.ico";

const Fiqh1Schedule = () => {
  return (
    <div className="my-10 py-10 bg-blue-200 rounded schedule_card">
      <div className="text-xl text-center my-10">
        <p className="">ইসলামী ফিকহ কোর্স - লেভেল ১</p>
        <p className=""> সপ্তাহে ৪ টি ক্লাস</p>
      </div>
      <div className="overflow-x-auto ">
        <table className="table w-3/4 mx-auto text-xl ">
          <thead>
            <tr>
              <th></th>
              <th className="text-xl">বার</th>
              <th className="text-xl">
                <span className="flex items-center">
                  <span>সময়</span>
                  <div className="ml-3">
                    <img className="" src={bd_flag} alt="" />
                  </div>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>১</th>
              <td>শনিবার</td>
              <td>রাত ৮:৩০</td>
            </tr>

            <tr>
              <th>২</th>
              <td>
                রবিবার
                <FontAwesomeIcon
                  className="text-sm text-red-600 ml-1 md:ml-2"
                  icon={faRecordVinyl}
                  fade
                />
              </td>
              <td>রাত ৯:০০</td>
            </tr>

            <tr>
              <th>৩</th>
              <td>সোমবার</td>
              <td>রাত ৮:৩০</td>
            </tr>

            <tr>
              <th>৪</th>
              <td>বুধবার</td>
              <td>রাত ৮:৩০</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Fiqh1Schedule;
