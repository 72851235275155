import React from "react";
import CardRamadanNextBatch from "./CardRamadanNextBatch";
import RamadanRegistrationForm from "./RamadanRegistrationForm";

export default function RamadanRegistration() {
  return (
    <div>
      <RamadanRegistrationForm />
      <CardRamadanNextBatch />
    </div>
  );
}
