import { faQuoteLeft } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import banner from "../assets/banner.png";

const carouselData = [
  {
    quote: "জেনে রাখো, আল্লাহ ছাড়া সত্য কোন উপাস্য নেই।",
    ref: "সূরা মুহাম্মাদ : ১৯",
  },
  {
    quote: "বল, হে আমার প্রতিপালক! আমার জ্ঞান বৃদ্ধি করুন।",
    ref: "সূরা ত্বা-হা: ১১৪",
  },
  {
    quote: "বল, যারা জানে এবং যারা জানে না, তারা কি সমান?",
    ref: "সূরা যুমার: ৯",
  },
  {
    quote: "আল্লাহর বান্দাদের মধ্যে জ্ঞানীরাই তাঁকে ভয় করে থাকে।",
    ref: "সূরা ফাত্বের: ২৮",
  },
  {
    quote:
      "আমি জ্বীন ও মানুষ জাতিকে শুধুমাত্র আমার ইবাদাতের জন্য সৃষ্টি করেছি।",
    ref: "সূরা যারিয়াত: ৫৬",
  },
  {
    quote: "যদি শিরক করাে, তবে তােমার সকল আমল নিষ্ফল হয়ে যাবে।",
    ref: "সূরা যুমার: ৬৫",
  },
  {
    quote: "বল, তিনি আল্লাহ, এক-অদ্বিতীয়",
    ref: "সূরা ইখলাস: ১",
  },
  {
    quote: "আল্লাহ অমুখাপেক্ষী (তিনি কারো মুখাপেক্ষি নন)",
    ref: "সূরা ইখলাস: ২",
  },
  {
    quote: "তিনি (আল্লাহ) কাউকে জন্ম দেননি, তাঁকেও কেউ জন্ম দেয়নি।",
    ref: "সূরা ইখলাস: ৩",
  },
  {
    quote: "এবং তাঁর (আল্লাহর) সমকক্ষ কেউই নেই।",
    ref: "সূরা ইখলাস: ৪",
  },
];

const Banner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
    }, 3500);

    return () => clearInterval(interval);
  }, [carouselData.length]);

  const { quote, ref } = carouselData[currentIndex];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center my-16">
      <div className="mx-auto">
        <img className="w-80 text-center" src={banner} alt="" />
      </div>
      <div className="col-span-2 mx-2 my-7 md:my-0 ">
        <div className="flex relative h-60 md:h-56 items-center">
          <FontAwesomeIcon
            className="text-yellow-300 -mt-44 md:-mt-24 absolute opacity-75 -z-10 quote_sign"
            icon={faQuoteLeft}
            size="6x"
            fade
          />
          <div className="mx-5  quote_texts">
            <p className="text-5xl md:text-6xl text-red-800 text-left quote_text">
              {quote}
            </p>
            <p className="italic text-right md:text-left">{ref}</p>
          </div>
        </div>
        <div className="flex gap-1 justify-center my-5">
          {carouselData.map((q, i) => (
            <div
              onClick={() => setCurrentIndex(i)}
              key={i}
              className={`w-3 h-3 rounded-full ${
                currentIndex === i ? "bg-red-400 " : "bg-slate-400"
              } cursor-pointer opacity-80`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Banner;
