import React from "react";
import { FaLink } from "react-icons/fa";

const group_link = (level, gender) => {
  const links = {
    2: {
      male: "https://t.me/+BV4JY7QjXwU1YjQ0",
      female: "https://t.me/+gzKtjxET40U5Yzhk",
    },

    1: {
      male: "https://t.me/+0fm9kJO_BsplZjFk",
      female: "https://t.me/+BrNuD5XKz8YxMzI8",
    },
  };

  return links?.[level]?.[gender] || "Link not found";
};

export default function RegistrationGroup({ gender, level }) {
  return (
    <a
      href={group_link(level, gender)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="relative overflow-hidden md:order-2 w-56 h-28 rounded py-2 px-5 my-3 md:my-0 bg-gradient-to-tl from-cyan-200 to-cyan-100 payment-card">
        <div className="flex justify-between">
          <p className="text-cyan-600">
            LEVEL {level} : {gender.toUpperCase()}
          </p>
          <FaLink className={`text-2xl text-cyan-600 ml-auto animate-pulse`} />
        </div>

        <FaLink className="text-8xl absolute -ml-6 mt-1 opacity-10 text-inherit " />

        <p className={`text-center font-bold text-xl my-2 text-cyan-600 `}>
          <span className="uppercase font-bold text-2xl">গ্রুপ লিংক</span>
        </p>
      </div>
    </a>
  );
}
