import React from "react";
import CardTafseerNextBatch from "./CardTafseerNextBatch";

export default function TafseerRegistration() {
  return (
    <div>
      <CardTafseerNextBatch />
    </div>
  );
}
