import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Page from "./Page";
import { ThemeContext } from "./contexts";

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode === "true" ? true : false;
  });

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  return (
    <>
      <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
        <Page />
        <ToastContainer />
      </ThemeContext.Provider>
    </>
  );
}

export default App;
