import { signOut } from "firebase/auth";
import React, { useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { AiFillHome } from "react-icons/ai";
import { FaBook, FaSignOutAlt } from "react-icons/fa";
import { IoPersonAdd } from "react-icons/io5";
import { MdLibraryBooks } from "react-icons/md";

import { useState } from "react";
import { Link } from "react-router-dom";
import coin from "../assets/donation/coin.gif";
import balck_logo from "../assets/logo/blackLogo-01.png";
import white_logo from "../assets/logo/whiteLogo-01.png";
import { ThemeContext } from "../contexts";
import auth from "../firebase.init";
import HeaderModal from "./HeaderModal";
import useAdmin from "./hooks/useAdmin";

const Header = () => {
  const [user, loading, error] = useAuthState(auth);
  const [admin, isLoading] = useAdmin(user);
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const [showLogOutModal, setShowLogOutModal] = useState(false);

  const handleTheme = (e) => {
    setDarkMode(!darkMode);
    // console.log("changing the theme to", );
  };

  return (
    <>
      {showLogOutModal && (
        <HeaderModal
          logOut={() => signOut(auth)}
          closeLogOutModal={() => setShowLogOutModal(!showLogOutModal)}
        />
      )}
      <div className="container mx-auto navbar bg-base-100">
        <div className="navbar-start">
          <Link to="/">
            <img
              className="w-auto"
              src={darkMode ? white_logo : balck_logo}
              alt=""
            />
          </Link>
        </div>

        {/* desktop menu */}
        <div className="navbar-center hidden lg:flex ">
          <ul className="menu  lg:menu-horizontal rounded-box">
            <li>
              <Link to="/" className="">
                <AiFillHome className="text-lg text-slate-600" />

                <span className="text-xs">হোম</span>
              </Link>
            </li>

            <li>
              <Link to="/courses" className="">
                <FaBook className="text-lg text-slate-600" />

                <span className="text-xs"> কোর্স সমূহ </span>
              </Link>
            </li>
            <li>
              <Link to="/noticeboard" className="">
                <MdLibraryBooks className="text-lg text-slate-600" />

                <span className="text-xs"> নোটিশবোর্ড </span>
              </Link>
            </li>
            <li>
              <Link to="/registration" className="">
                <IoPersonAdd className="text-lg text-green-500" />

                <span className="text-xs text-green-500"> রেজিস্ট্রেশন</span>
              </Link>
            </li>
            {user && (
              <li onClick={() => setShowLogOutModal(!showLogOutModal)}>
                <div className="">
                  <FaSignOutAlt className="text-lg text-red-500" />

                  <span className="text-xs text-red-500"> লগ আউট</span>
                </div>
              </li>
            )}
          </ul>
        </div>

        {/* Mobile Bottom Menu  */}
        <div className="rounded mx-auto flex z-10  text-center fixed bottom-0 w-full justify-center lg:hidden bg-slate-200 opacity-90 mobile-navbar">
          <ul className="menu w-full justify-around bg-base-200 menu-horizontal rounded-box">
            {!user && (
              <li>
                <Link to="/" className="flex flex-col">
                  <AiFillHome className="text-lg text-slate-600" />

                  <span className="text-xs">হোম</span>
                </Link>
              </li>
            )}
            <li>
              <Link to="/courses" className="flex flex-col">
                <FaBook className="text-lg text-slate-600" />

                <span className="text-xs"> কোর্স সমূহ </span>
              </Link>
            </li>
            <li>
              <Link to="/noticeboard" className="flex flex-col">
                <MdLibraryBooks className="text-lg text-slate-600" />

                <span className="text-xs"> নোটিশবোর্ড </span>
              </Link>
            </li>
            <li>
              <Link to="/registration" className="flex flex-col">
                <IoPersonAdd className="text-lg text-green-500" />

                <span className="text-xs text-green-500"> রেজিস্ট্রেশন</span>
              </Link>
            </li>
            {user && (
              <li onClick={() => setShowLogOutModal(!showLogOutModal)}>
                <div className="flex flex-col cursor-pointer">
                  <FaSignOutAlt className="text-lg text-red-500" />

                  <span className="text-xs text-red-500"> লগ আউট</span>
                </div>
              </li>
            )}
          </ul>
        </div>

        {/* theme changer */}
        <div className="navbar-end">
          <Link to="/donation">
            <img className="mr-3 w-10 cursor-pointer" src={coin} alt="" />
          </Link>
          <label className="flex cursor-pointer gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="5" />
              <path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
            </svg>
            <input
              onClick={(e) => handleTheme(e)}
              type="checkbox"
              defaultChecked={darkMode}
              className="toggle theme-controller"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
            </svg>
          </label>
        </div>
      </div>
    </>
  );
};

export default Header;
