import React, { useState } from "react";

export default function ReceiveMessage({ msg }) {
  const [imgUrl, setImgUrl] = useState("");

  const image_id = msg?.message?.photo?.[0]?.file_id;

  const getImage = async (quality = 0) => {
    const image_id_q = msg?.message?.photo?.[quality]?.file_id;

    await fetch(
      `https://api.telegram.org/${process.env.REACT_APP_TELEGRAM_API}/getFile?file_id=${image_id_q}`
    )
      .then((response) => response.json())
      .then((fileInfo) => {
        if (fileInfo.ok) {
          const file_path = fileInfo.result.file_path;
          setImgUrl(
            `https://api.telegram.org/file/${process.env.REACT_APP_TELEGRAM_API}/${file_path}`
          );
        } else {
          console.error("Failed to get image file path:", fileInfo.description);
        }
      });
  };

  const text = msg?.message?.text;
  const sticker = msg?.message?.sticker?.emoji;
  const forwarded = msg?.message?.forward_from;
  const group = msg?.message?.chat?.title;

  const timestamp = msg?.message?.date; // 1731451473

  const date = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
  const formattedDate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return (
    <div className="w-full h-[calc(100vh-10rem)] rounded bg-slate-300 mb-5 p-5 overflow-scroll">
      <p className="italic flex justify-between">
        <p className="">
          {msg?.message?.from.first_name}
          {group && <span> @ {group}</span>}
        </p>
        <p className="text-slate-500">{formattedDate}</p>
      </p>
      <div>
        {forwarded && (
          <a
            target="_black"
            href={`https://web.telegram.org/a/#${forwarded.id}`}
            className=" badge badge-accent"
          >
            {forwarded.first_name}
          </a>
        )}
        <p>
          {text &&
            text.split("\n").map((item, index) => (
              <span key={index}>
                {item}
                <br />
              </span>
            ))}
        </p>
        <p>{sticker}</p>
      </div>
      {msg?.message?.photo && (
        <div>
          {msg?.message?.photo?.map((i, index) => (
            <p
              className="cursor-pointer badge badge-outline m-1"
              onClick={() => getImage(index)}
              key={i.file_unique_id}
            >
              Img : {index + 1}
            </p>
          ))}
          <img className="w-1/3 rounded" src={imgUrl} alt="" />
        </div>
      )}
    </div>
  );
}
