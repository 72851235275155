import { faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import donate from "../../assets/donation/donate.png";
import donation from "../../assets/donation/donation.png";

import inbox from "../../assets/donation/sending_messages.png";
import bKash from "../../assets/payment/bKash.png";
import islamiBank from "../../assets/payment/islamic bank.png";
import nagad from "../../assets/payment/nagad.png";
import paypal from "../../assets/payment/paypal.png";
import rocket from "../../assets/payment/rocket.png";
import stcPay from "../../assets/payment/stc_pay.png";
import taptap from "../../assets/payment/taptap.png";
import upay from "../../assets/payment/upay.png";

export default function Donation() {
  return (
    <div className="container mx-auto px-5 my-10">
      <section className="my-10">
        <p className="text-center text-2xl">স্বাগতম!</p>
      </section>

      {/* Descripttion  */}
      <section className="my-10">
        <p className="text-xl">
          আপনারা জানেন,
          {/* <span className="badge bg-pink-300">শুধুমাত্র মহিলাদের জন্য</span> */}
        </p>
        <p>
          আমরা সমাজের সর্বস্তরের মানুষের কাছে ইসলামের দাওয়াত পৌছে দিতে কাজ করে
          যাচ্ছি।
        </p>
        <p>
          আমাদের কোর্সগুলো সবার কাছে পৌছে দিতে, বিশেষভাবে অর্থনৈতিকভাবে
          অসচ্ছলদের কাছে ফ্রীতে পৌছে দিতে স্কলারশিপের ব্যবস্থা রেখেছি।
        </p>
        <p>
          এইজন্য আপনাদের সহযোগিতা প্রয়োজন। আপনাদের দান-সাদকার টাকায় আমরা
          অভাবীদেরকে ফ্রীতে পড়ার সুযোগ করে দিতে পারি।
        </p>
      </section>

      {/* Donation */}
      <section className="my-20">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="order-2">
            <p className="text-xl my-4">সদকা জারিয়ায় অংশগ্রহন করুন</p>
            <p>
              আপনার দান-সাদকার টাকায় অন্য কেউ দ্বীনি ইলম অর্জন করবে। ইনশাআল্লাহ
              এই টাকা আপনার জন্য সদকা জারিয়া হিসেবে আল্লাহ কবুল করবেন।
            </p>
            <p>
              আমাদের শিক্ষার্থী ভাই-বোনেরা নিজে শেখার পাশাপাশি অন্যদেরকে শেখান
              এবং দাওয়াতি কাজে অংশগ্রহন করেন। তাই আশা করা যায়, আপনি যদি এমন
              ভাই-বোনদেরকে দ্বীনি ইলম অর্জনে সহযোগিতা করেন, আপনার আমলনামায়ও ভালো
              কাজের সওয়াব যুক্ত হতে থাকবে, ইনশাআল্লাহ।
            </p>
            <br></br>
            <p>এমন সুযোগ কেন হাতছাড়া করবেন?</p>
          </div>
          <div className="order-1 md:order-3">
            <img className="rounded-lg block mx-auto my-10 " src={donation} />
          </div>
        </div>
      </section>

      {/* Amount  */}
      <section className="my-40">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
          <div>
            <img className="mx-auto" src={donate} alt="" />
          </div>
          <div>
            <h3 className="text-xl text-blue-600 my-5">
              যেকোন পরিমান টাকা দান করুন
            </h3>
            <p>
              কোন এমাউন্ট ছোট নয়। আপনার দান করা যে কোন পরিমান টাকা আরেকজনকে
              দুশ্চিন্তা মুক্ত করবে।
            </p>
            <p>
              কোন একটি কোর্সের জন্য ১০/৫ জনের স্কলারশিপের ব্যবস্থা করতে পারেন।
              যেমনঃ আক্বিদা কোর্সের লেভেল ১ এর কোর্স ফী ১৫০০ টাকা। সেক্ষেত্রে ১০
              জনের জন্য ১০ * ১৫০০ = ১৫,০০০ টাকা দিতে পারেন।
            </p>
            <p>
              অথবা যে কোন এমাউন্ট পাঠাতে পারেন, যেমন ২০,০০০ টাকা , ১০,০০০ টাকা,
              ৫,০০০ টাকা ।
            </p>
          </div>
        </div>
      </section>

      {/* payment  */}
      <section className="mb-10" id="send-money">
        <div>
          <h2 className="text-2xl text-green-600">
            <FontAwesomeIcon className="pr-5" icon={faMoneyBillTransfer} />
            কিভাবে টাকা পাঠাবেন?
          </h2>
          <p className=" mt-3">
            নিচের যে কোন নাম্বারে
            <span className="text-red-500 font-bold"> সেন্ডমানি </span> করুন।
          </p>
          <p className="">
            <strong className="text-red-600">দোকান থেকে টাকা না পাঠিয়ে </strong>{" "}
            নিজের/বাড়ির কারো নাম্বার থেকে পাঠান, তাহলে টাকাটা গেল কিনা, ঠিক
            নাম্বারে গেল কিনা নিশ্চিত হতে পারবেন।{" "}
          </p>
          <p className="mt-1 mb-5">
            <FontAwesomeIcon className="text-blue-500" icon={"image"} /> সম্ভব
            হলে টাকা পাঠানোর পর{" "}
            <strong className="text-blue-600">
              স্ক্রিনশট/মানি রিসিটের ছবি
            </strong>{" "}
            তুলুন।{" "}
          </p>
          {/* <p className='mb-5'><FontAwesomeIcon className='text-red-500' icon={'ban'}/> টাকা পাঠানোর এই নাম্বারগুলোতে <strong className='text-red-600'>কল করবেন না</strong> । যে কোন প্রয়োজনে আমাদের সাথে <a target="_blank" href='https:m.me/alharamain.ac' className='bg-sky-300 px-1 rounded-sm'>মেসেঞ্জারে</a>/<a target="_blank" href='https:wa.me/+966578621278' className='bg-lime-400 px-1 rounded-sm'>হোয়াটসঅ্যাপে</a>/<a target="_blank" href='https:t.me/+966578621278' className='bg-blue-400 px-1 rounded-sm'>টেলিগ্রামে</a> যোগাযোগ করুন।</p> */}

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
            <div className="p-5 flex bg-orange-200 rounded">
              <img className="w-8 mr-5" src={nagad} alt="" />
              <p className="text-2xl font-bold">01883 99 80 81</p>
            </div>
            <div className="p-5 flex bg-slate-200 rounded">
              <img className="w-8 mr-5" src={upay} alt="" />
              <p className="text-2xl font-bold">01883 99 80 81</p>
            </div>
            <div className="p-5 flex bg-indigo-200 rounded">
              <img className="w-8 mr-5" src={rocket} alt="" />
              <p className="text-2xl font-bold">01883 99 80 815</p>
            </div>
            <div className="p-5 flex bg-sky-200 rounded">
              <img className="w-8 mr-2" src={paypal} alt="" />
              <p className="md:pr-3">
                <span className="font-bold">faisal42abdullah</span>@gmail.com
              </p>
            </div>
            <div className="p-5 flex bg-sky-200 rounded">
              <img className="w-8 mr-3" src={stcPay} alt="" />
              <p className="text-2xl font-bold">+966 5350 17346</p>
            </div>
            <div className="p-5 flex bg-red-200 rounded">
              <img className="w-8 mr-5" src={bKash} alt="" />
              <p className="text-2xl font-bold">01883 99 80 81</p>
            </div>
            <div className="p-5 flex bg-red-200 rounded">
              <img className="w-8 mr-5" src={bKash} alt="" />
              <p className="text-2xl font-bold">01955 75 62 02</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            <div>
              <p className="mt-5">সরাসরি ব্যাংকে পাঠাতে পারেন</p>
              <div className="flex gap-5 mt-5">
                <div className="w-16">
                  <img className="rounded" src={islamiBank} alt="" />
                </div>
                <div>
                  <p className="">
                    {" "}
                    A/C Name:{" "}
                    <span className="text-green-600">
                      <strong>Abdullah Al Baki</strong>
                    </span>
                  </p>
                  <p className=" ">
                    {" "}
                    A/C No:{" "}
                    <span className="text-green-600">
                      <strong>20501 1702 0073 9805</strong>
                    </span>
                  </p>
                  <p className=" ">
                    {" "}
                    Branch:{" "}
                    <span className="text-green-600">
                      <strong>Rangpur</strong>
                    </span>
                  </p>
                  <p className=" ">
                    {" "}
                    Branch Code:{" "}
                    <span className="text-green-600">
                      <strong>IBBLBDDH117</strong>
                    </span>
                  </p>
                  <p className=" mb-5"> Islami Bank Bangladesh Ltd</p>
                </div>
              </div>
            </div>
            <div>
              <p className="mt-5">
                ট্যাপট্যাপ সেন্ডের মাধ্যমেও টাকা পাঠাতে পারেন
              </p>
              <div className="flex gap-5 mt-5">
                <div className="w-16">
                  <img className="" src={taptap} alt="" />
                </div>
                <div>
                  <p className="">
                    {" "}
                    Phone :{" "}
                    <span className="text-green-600">
                      <strong>+880 1883 99 80 81</strong>
                    </span>
                  </p>
                  <p className="">
                    {" "}
                    Full Name:{" "}
                    <span className="text-green-600">
                      <strong>Faisal Faisal</strong>
                    </span>
                  </p>
                  <p className="">
                    {" "}
                    YYYY:{" "}
                    <span className="text-green-600">
                      <strong>1996</strong>
                    </span>
                  </p>
                  <p className="mb-5">
                    {" "}
                    Address:{" "}
                    <span className="text-green-600">
                      <strong>Rangpur, Bangladesh</strong>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <p className="mt-5">
                ভারত থেকে টাকা পাঠানোর জন্য ফোন পে নাম্বার পেতে ইনবক্সে মেসেজ
                দিন।
                {/* <span className="text-blue-600">১৩০০ রুপি</span> পাঠাবেন। */}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* imbox  */}
      <section className="my-20">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center">
          <div className="order-2 mx-auto">
            <p className="text-xl my-4 text-orange-600">
              আমাদের সাথে যোগাযোগ করুন
            </p>
            <p>অনুগ্রহ করে টাকা পাঠানোর পর আমাদেরকে ইনবক্সে মেসেজ দিন।</p>
            <p>কোন কোর্সের জন্য, কতো টাকা দিতে চান, আমাদেরকে জানান।</p>
            <br></br>
            <p>সম্ভব হলে টাকা পাঠানোর স্ক্রিনশট দিন।</p>
          </div>
          <div className="order-1 md:order-3">
            <img className="rounded-lg block mx-auto my-10 " src={inbox} />
          </div>
        </div>
      </section>
    </div>
  );
}
