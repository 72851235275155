import React, { useState } from "react";
import CardFiqhNextBatch from "./CardFiqhNextBatch";
import FiqhPayment from "./FiqhPayment";
import FiqhRegistrationForm from "./FiqhRegistrationForm";
import RegistrationGroup from "./RegistrationGroup";

export default function Hello() {
  const [group, setGroup] = useState({
    gender: "",
    level: "",
  });

  return (
    <section>
      <div className="container mx-auto my-10 px-5">
        <p className="text-xl text-blue-600 mt-10 mb-5 text-center">
          ইসলামী ফিকহ কোর্সের ৯ম ব্যাচে ভর্তি শুরু হয়েছে!
        </p>

        <div className="mx-auto my-5 text-center ">
          <ul className="steps steps-vertical">
            <li className="step step-primary">
              <a href="#send-money">১৫০০ টাকা সেন্ডমানি করুন</a>
            </li>
            <li className="step step-primary">
              <a href="#reg-form">ভর্তি ফর্ম পূরন করুন</a>
            </li>
            <li className="step step-primary">
              <a href="#group-link">টেলিগ্রাম গ্রুপে জয়েন করুন</a>
            </li>
          </ul>
        </div>

        <FiqhRegistrationForm setGroup={setGroup} />

        <section className="mt-10">
          {group.gender && group.level && (
            <div>
              <p className="text-xl text-blue-600 mt-24">গ্রুপে জয়েন করুন</p>
              <p>
                আপনার পেমেন্ট করে করে গ্রুপে অ্যাড করা হবে। পেমেন্ট চেক করতে সময়
                লাগতে পারে।
              </p>
              <p className="mb-5">
                আপনার কাছে টাকা পাঠানোর (সেন্ডমানি) ছবি বা স্ক্রিনশট থাকলে
                ইনবক্সে দিব। তাহলে দ্রুত গ্রুপে অ্যাড করা সম্ভব হবে।
              </p>
              <RegistrationGroup gender={group.gender} level={group.level} />
            </div>
          )}
        </section>

        <p className="text-xl text-blue-600 mt-24">সেন্ডমানি করুন</p>
        <FiqhPayment />
        <CardFiqhNextBatch />
      </div>
    </section>
  );
}
