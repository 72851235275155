import React from "react";

export default function Profile_1_Skeleton() {
  return (
    <div className="mx-auto my-20 flex w-10/12 flex-col gap-4">
      <div className="flex flex-col md:flex-row justify-between mb-20 gap-5">
        <div className="flex items-center gap-4">
          <div className="skeleton h-16 w-16 shrink-0 rounded-full"></div>
          <div className="flex flex-col gap-4">
            <div className="skeleton h-4 w-20"></div>
            <div className="skeleton h-4 w-28"></div>
          </div>
        </div>
        <div className="skeleton h-32 w-full md:w-52"></div>
      </div>
      <hr className="my-10" />
      <div className="flex flex-col md:flex-row justify-around gap-10">
        <div className="skeleton h-32 w-full"></div>
        <div className="skeleton h-32 w-full"></div>
        <div className="skeleton h-32 w-full"></div>
      </div>
    </div>
  );
}
