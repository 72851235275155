import React from "react";
import CardTajweedNextBatch from "./CardTajweedNextBatch";

export default function TajweedRegistration() {
  return (
    <div>
      <CardTajweedNextBatch />
    </div>
  );
}
