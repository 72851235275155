import React from 'react';

const Verifying = () => {
    return (
        <div className='min-h-screen flex justify-center items-center'>
            <button className="btn btn-error loading">verifying</button>
        </div>
    );
};

export default Verifying;