import {
  faFacebook,
  faFacebookMessenger,
  faInstagram,
  faTelegram,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import black_logo from "../assets/logo/blackLogo-01.png";
import white_logo from "../assets/logo/whiteLogo-01.png";
import { ThemeContext } from "../contexts";

const Footer = () => {
  const { darkMode } = useContext(ThemeContext);

  return (
    <div className="pb-28 md:pb-12 lg:pb-5">
      <footer className="footer p-10 bg-base-200 text-base-content">
        <div>
          <span className="footer-title">কোর্স সমূহ</span>
          <Link className="link link-hover" to="/courses/aqeedah">
            সহীহ আক্বীদাহ
          </Link>

          <Link className="link link-hover" to="/courses/fiqh">
            ইসলামী ফিকহ
          </Link>
          <Link className="link link-hover" to="/courses/seerat">
            সীরাতুন নবী (সা)
          </Link>
          <Link className="link link-hover" to="/courses">
            সকল কোর্স
          </Link>
        </div>
        <div>
          <span className="footer-title">প্রতিষ্ঠান</span>
          <Link className="link link-hover" to="/registration">
            রেজিস্ট্রেশন
          </Link>
          <Link className="link link-hover" to="/about-us">
            আমাদের সম্পর্কে
          </Link>
          <p>লাইসেন্স নং</p>
          <p>BL-2022-23000339</p>
        </div>
        <div>
          <span className="footer-title">সাপোর্ট</span>
          <Link className="link link-hover" to="/aqeedah-due">
            বকেয়া পরিশোধ করুন
          </Link>
          <p className="">এডমিন</p>
          <p className="">+966578621278</p>
        </div>
      </footer>
      <footer className="pb-10 footer flex md:flex-row flex-col justify-between items-center   px-10 py-4 border-t bg-base-200 text-base-content border-base-300">
        <div className="items-center grid-flow-col order-2">
          <img src={darkMode ? white_logo : black_logo} alt="" />
        </div>
        <div className="md:place-self-center md:justify-self-end order-1 md:order-3">
          <div className="">
            <div className="grid grid-flow-col gap-4">
              <a
                className="text-2xl text-red-600"
                href="https://www.youtube.com/@alharamainislamicacademy"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className=""
                  icon={faYoutube}
                ></FontAwesomeIcon>
              </a>
              <a
                className="text-2xl text-blue-600"
                href="https://www.facebook.com/alharamain.ac"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className=""
                  icon={faFacebook}
                ></FontAwesomeIcon>
              </a>
              <a
                className="text-2xl text-pink-600"
                href="https://www.instagram.com/alharamain.ac/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className=""
                  icon={faInstagram}
                ></FontAwesomeIcon>
              </a>
              <a
                className="text-2xl text-indigo-600"
                href="https://m.me/alharamain.ac"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className=""
                  icon={faFacebookMessenger}
                ></FontAwesomeIcon>
              </a>
              <a
                className="text-2xl text-green-600"
                href="https://wa.me/966578621278"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className=""
                  icon={faWhatsapp}
                ></FontAwesomeIcon>
              </a>
              <a
                className="text-2xl text-sky-600"
                href="https://t.me/+966578621278"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className=""
                  icon={faTelegram}
                ></FontAwesomeIcon>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
