import React, { useState } from "react";
import { formatDateTime } from "../../../utils/submissonDateTime";
import Loading from "./../../../Loading/Loading";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

const s_DateTime = formatDateTime();

const initialFormContent = {
  submissionDateTime: s_DateTime,
  course: "আক্বীদাহ",
  batch: "",
  level: "",
  sn: "",
  name: "",
  amount: "",
};

export default function AqeedahDueForm() {
  const [formData, setFormData] = useState(initialFormContent);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [courseName, setCourseName] = useState("");

  const app_script_api =
    "https://script.google.com/macros/s/AKfycbwoKJMhoaT0D39nM7mWzw3NP4K-8R8l3MEyUnJTQl5Fi55yN5GxabVASdsJWAIkng1A1Q/exec";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (formData.course === "") {
      toast.error("কোর্সের নাম লেখেননি");
      return;
    }

    if (formData.sn === "") {
      toast.error("আপনার সিরিয়াল নাম্বার লেখেননি");
      return;
    }
    if (formData.name === "") {
      toast.error("আপনার নাম লেখেননি");
      return;
    }

    if (formData.amount === "") {
      toast.error("টাকার এমাউন্ট লেখেননি");
      return;
    }
    // if (formData.payment === "") {
    //   toast.error("সেন্ডমানি করেছেন কি?");
    //   return;
    // }

    setIsLoading(true);

    const formDatab = new FormData();
    Object.keys(formData).forEach((key) => {
      formDatab.append(key, formData[key]);
    });

    fetch(app_script_api, {
      method: "POST",
      body: formDatab,
    })
      .then((res) => res.text())
      .then((data) => {
        console.log(data);
        setFormData(initialFormContent); //resetting the form
        setIsLoading(false);
        setFormSubmitted(true);
        // toast.success("সফল হয়েছে!");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (formSubmitted) {
    return (
      <div className="container mx-auto my-10 text-center px-5">
        <p className="text-xl text-blue-600 my-5">
          ধন্যবাদ! আপনার পেমেন্টের তথ্য অ্যাডমিনদের কাছে পাঠানো হয়েছে।
        </p>
        <p>শিঘ্রই আপনার প্রোফাইল আপডেট করে দেওয়া হবে, ইনশাআল্লাহ।</p>
        <p></p>
        <p className="text-9xl text-green-500">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-green-500"
            size="1x"
            fade
          />
        </p>
      </div>
    );
  }
  if (isLoading) return <Loading />;

  return (
    <section>
      <div className="container mx-auto my-10 px-5">
        <div className="card flex-shrink-0 w-full shadow-2xl bg-base-100">
          <div className="card-body">
            <form className="form" onSubmit={handleFormSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">কোর্স</span>
                    </label>
                    <input
                      type="text"
                      placeholder="কোর্সের নাম লিখুন, যেমনঃ আক্বীদাহ"
                      className="input input-bordered w-full max-w-xs"
                      name="course"
                      value={formData.course}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">আপনার সিরিয়াল নাম্বার</span>
                    </label>
                    <input
                      type="text"
                      placeholder="আপনার সিরিয়াল নাম্বার লিখুন"
                      className="input input-bordered w-full max-w-xs"
                      name="sn"
                      value={formData.sn}
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="form-control">
                    <label className="label">
                      <span className="label-text">ব্যাচ নং</span>
                    </label>
                    <input
                      type="text"
                      placeholder="ব্যাচ নং লিখুন"
                      className="input input-bordered w-full max-w-xs"
                      name="batch"
                      value={formData.batch}
                      onChange={handleChange}
                    />
                  </div> */}

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">আপনার নাম</span>
                    </label>
                    <input
                      type="text"
                      placeholder="আপনার নাম লিখুন"
                      className="input input-bordered w-full max-w-xs"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">কতো টাকা পাঠিয়েছেন?</span>
                    </label>
                    <input
                      type="text"
                      placeholder="পরিমান লিখুন"
                      className="input input-bordered w-full max-w-xs"
                      name="amount"
                      value={formData.amount}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-control mt-10">
                    <button
                      type="submit"
                      className="btn btn-primary w-full max-w-xs"
                    >
                      জমা দিন
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
