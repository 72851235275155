import React, { useEffect, useState } from "react";

const web_api =
  "https://script.google.com/macros/s/AKfycbxJzcz5MMmZ-385ffj4uxvzJQCHYD6WgKnezB3MRmXGxKsvJlHh0jkBztinhGkiNHgF/exec";

export default function SpreadsheetoToReact() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(web_api)
      .then((response) => response.json())
      .then((data) => {
        // Process the fetched data
        console.log(data);
        // Update your React component state or UI with the data
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <div className="my-10">
      <p className="text-center text-2xl">Spreadsheet To React</p>
      <ul>
        {data.map((item, index) => (
          <li key={index}>{Object.values(item).join(", ")}</li>
        ))}
      </ul>
    </div>
  );
}
