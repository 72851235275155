import partyFlag from "../../../assets/partyFlag.png";
import CardRamadanNextBatch from "./Registration/CardRamadanNextBatch";

export default function RamadanSiyamInfo() {
  return (
    <div className="container mx-auto px-5 my-10">
      <section>
        <div className="rounded bg-gradient-to-tl from-sky-200 flex justify-between header-card">
          <div className="p-5">
            <p className="text-3xl">রমাদান ও সিয়াম কোর্সে</p>
            <p className="text-4xl">স্বাগতম!</p>
          </div>
          <div className="p-5 hidden md:inline">
            <div className="flex justify-between">
              <div className="text-right font-serif align-middle mr-10">
                <p className="text-4xl ">دورة فقه الصيام</p>
                <p className="text-2xl">من الكتاب و السنة</p>
              </div>
              <div>
                <img src={partyFlag} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Course Descripttion  */}
      <section className="my-10">
        <p>এই পেইজটি শিঘ্রই আপডেট করা হবে, ইনশাআল্লাহ।</p>
      </section>

      <CardRamadanNextBatch />
    </div>
  );
}
