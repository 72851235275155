import React from "react";
import trophy from "../../assets/icons/trophy.gif";
const LoadingTrophy = () => {
  return (
    <div className="min-h-screen flex justify-center items-center">
      <div>
        <img src={trophy} alt="" />
      </div>
    </div>
  );
};

export default LoadingTrophy;
