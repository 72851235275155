import React, { useEffect, useState } from "react";
import ReceiveMessage from "./ReceiveMessage";
import SendMessage from "./SendMessage";
import getUniqueKeywords from "./getUniqueKeywords";

export default function TelegramBotMessage() {
  const [chatlist, setChatlist] = useState([]);
  const [chat, setChat] = useState([]);
  const [r_message, setR_message] = useState();

  console.log("API :", process.env.REACT_APP_TELEGRAM_API);
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        await fetch(
          `https://api.telegram.org/${process.env.REACT_APP_TELEGRAM_API}/getUpdates`
        )
          .then((response) => response.json())
          .then((data) => {
            setChat(data.result);
            setChatlist(getUniqueKeywords(data.result));
          });
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [r_message]);

  const handleReceiveMessage = (message) => {
    setR_message(message);
  };
  console.log(chat);
  return (
    <section className="">
      <div className="drawer lg:drawer-open">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content ">
          <div className="mx-5 min-h-screen">
            <ReceiveMessage msg={r_message} />
            <SendMessage receiver={r_message} />
          </div>
          <label
            htmlFor="my-drawer-2"
            className="btn btn-primary drawer-button lg:hidden"
          >
            Open List
          </label>
        </div>
        <div className="drawer-side">
          <label
            htmlFor="my-drawer-2"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <ul className="bg-base-200 text-base-content min-h-full w-80 p-4">
            <div className="mx-auto text-center min-h-10  my-3">
              <p>Total Chat: {chat?.length}</p>
            </div>
            {/* Sidebar content here */}
            {chat.map((c, index) => (
              <li key={c.update_id}>
                <span
                  className="flex justify-between items-center cursor-pointer my-1 hover:bg-slate-300 p-1 rounded"
                  onClick={() => handleReceiveMessage(c)}
                >
                  <span>
                    {index + 1} {" : "}
                    {c.message.from.first_name}
                    <span className="opacity-0">{" : "}</span>
                  </span>

                  <span
                    className={`text-xs badge ${
                      c.message.chat.type === "private"
                        ? "badge-success"
                        : "badge-warning"
                    }`}
                  >
                    {c.message.from.id}
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
